import React from 'react'
import { Link, graphql } from 'gatsby'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import GraphQLErrorList from '../components/graphql-error-list'

import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query LawyersPageQuery {
    lawyers: sanityLawyersPage(_id: { regex: "/(drafts.|)lawyersPage2/" }) {
      title
      title1
      body
      slide1Image {
        asset {
          _id
        }
      }
      generic {
        asset {
          _id
        }
      }
    }

    students: sanityStudentsPage(_id: { regex: "/(drafts.|)studentsPage/" }) {
      generic {
        asset {
          _id
        }
      }
    }

    people: allSanityPerson(sort: { fields: [lastname], order: ASC }) {
      edges {
        node {
          id
          name
          lastname
          position
          email
          tel
          image {
            asset {
              _id
            }
          }
          slug {
            current
          }
          _rawBio
        }
      }
    }
  }
`

const ParagraphClass = 'dib relative w-100 measure-wide dark-gray fw4 mb4'
const HeadingClass = 'dib relative w-100 measure f2 fw5 mv3'

const LawyersPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const lawyers = data && data.lawyers
  const students = data && data.students
  const personNodes =
    data && data.people && mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)

  if (!lawyers) {
    throw new Error(
      'Missing "Lawyers" page data. Open the studio at http://localhost:3333 and add "Lawyers" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={lawyers.title} />
      <article>
        {/* Lawyers */}
        <section className="dib relative w-100 bg-white black lh-copy f4" id="lawyers">
          <div className="db center mw8 ph4 pt6 pb4">
            <h1 className={HeadingClass}>{lawyers.title1}</h1>
            <p className={ParagraphClass}>{lawyers.body}</p>
          </div>
          {lawyers.slide1Image && lawyers.slide1Image.asset && lawyers.slide1Image.asset._id ? (
            <img
              className="dib relative w-100 pb2"
              src={imageUrlFor(buildImageObj(lawyers.slide1Image))}
              alt={lawyers.title1}
            />
          ) : null}
        </section>
        {/* Team */}
        <section className="dib relative bg-white w-100 black lh-copy f4 mb0 mb4-l" id="team">
          <div className="db center mw8 ph4 pv4">
            <div className="row">
              {personNodes.map((object, index) => (
                <div className="col-xs-12 col-md-4" key={`person-${index}`}>
                  {object.slug.current === 'articling-students' ? (
                    <Link className="dib relative w-100 mb4 link dim" to={`/students`}>
                      <div
                        className="dib relative w-100 br3 mb3 lawyer-pic"
                        style={{
                          background: `url(${imageUrlFor(
                            buildImageObj(students.generic)
                          )}) no-repeat center center / cover`
                        }}
                      >
                        &nbsp;
                      </div>
                      <div className="dib relative w-100">
                        <strong className="dib relative w-100 f4 b black mb2">
                          {object.name} {object.lastname}
                        </strong>
                        {object.position ? (
                          <p className="dib relative w-100 measure-wide dark-gray fw4 f5 mv0">
                            {object.position}
                          </p>
                        ) : null}
                      </div>
                    </Link>
                  ) : (
                    <Link
                      className="dib relative w-100 mb4 link dim"
                      to={`/lawyers/${object.slug.current}`}
                    >
                      {object.image && object.image.asset && object.image.asset._id ? (
                        <div
                          className="dib relative w-100 br3 mb3 lawyer-pic"
                          style={{
                            background: `url(${imageUrlFor(
                              buildImageObj(object.image)
                            )}) no-repeat center center / cover`
                          }}
                        >
                          &nbsp;
                        </div>
                      ) : (
                        <div
                          className="dib relative w-100 br3 mb3 bg-black lawyer-pic"
                          style={{
                            background: `url(${imageUrlFor(
                              buildImageObj(lawyers.generic)
                            )}) no-repeat center center / cover`
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                      <div className="dib relative w-100">
                        <strong className="dib relative w-100 f4 b black mb2">
                          {object.name} {object.lastname}
                        </strong>
                        {object.position ? (
                          <p className="dib relative w-100 measure-wide dark-gray fw4 f5 mv0">
                            {object.position}
                          </p>
                        ) : null}
                      </div>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default LawyersPage
